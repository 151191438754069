import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useApiAxios from "../../api/useApiAxios";
import ReactPaginate from "react-paginate"; // Import the ReactPaginate component
import "./allUser.css";

const AllUser = () => {
  const { getMarketsOwned, sendEmailBulk, getMarketVendorFilterApi } =
    useApiAxios();
  const [marketsData, setMarketsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [emailContent, setEmailContent] = useState("");
  const [emailTitle, setEmailTitle] = useState(""); // State for the email title
  const [showModal, setShowModal] = useState(false); // Modal visibility control
  const [showMarketsModal, setShowMarketsModal] = useState(false); // State for showing markets modal
  const [selectedUserMarkets, setSelectedUserMarkets] = useState([]); // State to store the selected user's markets
  const [selectedUserMarketOwnerName, setSelectedUserMarketOwnerName] =
    useState([]); // State to store the selected user's markets

  const [selectedMarketVendors, setSelectedMarketVendors] = useState(null); // Vendor display toggle
  const [vendors, setVendors] = useState({}); // To store the vendors for each market
  const [selectedVendor, setSelectedVendor] = useState(null); // Store the selected vendor for modal
  const staticImagePath = process.env.REACT_APP_IMG_URL;

  // Pagination states
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10); // Number of items per page, initialized to 10

  useEffect(() => {
    const fetchMarkets = async () => {
      try {
        const response = await getMarketsOwned();
        setMarketsData(response.data.data);
      } catch (err) {
        setError("Failed to fetch markets data");
      } finally {
        setLoading(false);
      }
    };
    fetchMarkets();
  }, []);

  // Handle page click (for pagination)
  const handlePageClick = (data) => {
    setCurrentPage(data.selected); // Set the page index to display
  };

  // Get the current page data
  const currentPageData = marketsData.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  // Toggle select all (only selects the users on the current page)
  const toggleSelectAll = () => {
    const currentPageUserIds = currentPageData.map((user) => user._id);
    const allSelected = currentPageUserIds.every((id) =>
      selectedUsers.includes(id)
    );

    if (allSelected) {
      setSelectedUsers(
        selectedUsers.filter((id) => !currentPageUserIds.includes(id))
      );
    } else {
      setSelectedUsers([...selectedUsers, ...currentPageUserIds]);
    }
  };

  // Handle individual user selection
  const toggleUserSelection = (userId) => {
    setSelectedUsers((prev) =>
      prev.includes(userId)
        ? prev.filter((id) => id !== userId)
        : [...prev, userId]
    );
  };

  // Check if all users on the current page are selected
  const isAllSelected = currentPageData.every((user) =>
    selectedUsers.includes(user._id)
  );

  const sendEmail = async () => {
    if (!emailTitle || !emailContent) {
      alert("Please enter both a title and content for the email.");
      return;
    }

    const usersToSendEmail = marketsData.filter((user) =>
      selectedUsers.includes(user._id)
    );
    const subAdminIds = usersToSendEmail.map((user) => user._id);

    // Create a FormData object to send the data
    const formData = new FormData();
    formData.append("adminId", "your-admin-id"); // Replace with your actual admin ID
    formData.append("title", emailTitle); // The title entered by the user
    formData.append("body", emailContent); // The email content entered by the user
    formData.append("subAdminIds", JSON.stringify(subAdminIds)); // This turns it into a string

    try {
      // Send the email using the sendEmailBulk API
      const response = await sendEmailBulk(formData); // assuming sendEmailBulk handles the POST request
      console.log("Email sent successfully:", response.data);
      closeModal(); // Close the modal after the email is sent
    } catch (error) {
      console.error("Error sending email:", error);
      // Handle any error from the API
    }
  };

  // Close the modal and reset email content
  const closeModal = () => {
    setShowModal(false);
    setEmailContent("");
    setEmailTitle("");
  };

  // Open the markets modal with the markets of the selected user
  const openMarketsModal = (user, marketOwnerName) => {
    setSelectedUserMarkets(user.markets);
    setSelectedUserMarketOwnerName(marketOwnerName);
  };

  // Toggle displaying the vendors for a specific market
  const toggleVendorsForMarket = async (marketId) => {
    if (selectedMarketVendors === marketId) {
      setSelectedMarketVendors(null); // Hide vendors if they are already visible
    } else {
      // Check if vendors for the market are already fetched
      if (!vendors[marketId]) {
        try {
          const response = await getMarketVendorFilterApi(marketId); // Fetch vendors for the market
          setVendors((prevVendors) => ({
            ...prevVendors,
            [marketId]: response.data.marketVendor
          }));
        } catch (error) {
          console.error("Error fetching market vendors:", error);
        }
      }
      setSelectedMarketVendors(marketId); // Show vendors for the selected market
    }
  };

  // Open the modal to show vendor details
  const openVendorDetailsModal = (vendor) => {
    setSelectedVendor(vendor);
  };

  // Close modal on outside click
  const closeModalOnOutsideClick = (e) => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };

  const dummyImagePath = "/path/to/dummy/image"; // Path to your fallback image

  return (
    <>
      <div className="main-panel">
        <div className="content">
          <div className="panel-header">
            <div className="page-inner py-5">
              <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
                <ul className="breadcrumbs">
                  <li className="nav-home">
                    <Link to="/">
                      <i className="flaticon-home" />
                    </Link>
                  </li>
                  <li className="separator">
                    <i className="flaticon-right-arrow" />
                  </li>
                  <li className="nav-item">
                    <a href="#">All Users</a>
                  </li>
                  <li className="separator">
                    <i className="flaticon-right-arrow" />
                  </li>
                  <li className="nav-item">
                    <a href="#">Send Notice</a>
                  </li>
                </ul>
              </div>
              <div className="card-header d-flex justify-content-between align-items-center">
                <h4 className="card-title">All Users</h4>
                <div className="form-check mb-4">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={isAllSelected}
                    onChange={toggleSelectAll}
                  />
                  <label className="form-check-label">
                    Select All Market Owners
                  </label>
                  <button
                    className="btn btn-primary ml-3"
                    onClick={() => setShowModal(true)}
                  >
                    Send Email to Selected Users
                  </button>
                </div>
                <div>
                  <label htmlFor="itemsPerPage">Items per page: </label>
                  <select
                    id="itemsPerPage"
                    value={itemsPerPage}
                    onChange={(e) => setItemsPerPage(Number(e.target.value))}
                    className="form-control"
                    style={{ width: "auto", display: "inline-block" }}
                  >
                    <option value={10}>Show 10</option>
                    <option value={20}>Show 20</option>
                    <option value={30}>Show 30</option>
                    <option value={50}>Show 50</option>
                    <option value={100}>Show 100</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="page-inner mt--5">
            {loading ? (
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : error ? (
              <p>{error}</p>
            ) : (
              <div className="row">
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table tabless">
                          <thead>
                            <tr>
                              <th>Select</th>
                              <th>Market Owner</th>
                              <th>Markets Owned</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentPageData.map((user) => (
                              <tr key={user._id}>
                                <td>
                                  <input
                                    type="checkbox"
                                    checked={selectedUsers.includes(user._id)}
                                    onChange={() =>
                                      toggleUserSelection(user._id)
                                    }
                                  />
                                </td>
                                <td>{user.name}</td>
                                <td>
                                  <button
                                    className="btn btn-info btn-sm"
                                    onClick={() =>
                                      openMarketsModal(user, user.name)
                                    }
                                  >
                                    View Markets
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>

                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          pageCount={Math.ceil(
                            marketsData.length / itemsPerPage
                          )}
                          onPageChange={handlePageClick}
                          containerClassName={
                            "pagination justify-content-center small-pagination"
                          } // Add the small-pagination class here
                          activeClassName={"active"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Right Column for Market Details */}
                <div className="col-md-8">
                  <div className="card shadow-lg border-0 rounded-lg">
                    <div className="card-body">
                      <h2
                        className="mb-4"
                        style={{
                          color: "#000",
                          fontSize: "24px",
                          fontWeight: "bold"
                        }}
                      >
                        Markets Owned: {selectedUserMarketOwnerName}
                      </h2>

                      {selectedUserMarkets.length > 0 ? (
                        <ul className="list-unstyled">
                          {selectedUserMarkets.map((market) => (
                            <li
                              key={market._id}
                              className="mb-4 d-flex align-items-start p-3 bg-light rounded-lg shadow-sm"
                            >
                              <div className="d-block justify-content-between align-items-center">
                                <div className="market-info mr-4 text-nowrap">
                                  <h6 className="font-weight-bold text-secondary">
                                    {market.name}
                                  </h6>
                                </div>

                                <button
                                  className="btn btn-info btn-sm font-weight-bold"
                                  onClick={() =>
                                    toggleVendorsForMarket(market._id)
                                  }
                                >
                                  <i className="fas fa-store mr-2"></i> View
                                  Vendors
                                </button>
                              </div>

                              <div className="d-flex justify-content-between w-100">
                                {selectedMarketVendors === market._id && (
                                  <div className="vendor-info w-50 ml-4">
                                    <h6 className="font-weight-bold text-success">
                                      Vendors:
                                    </h6>
                                    <div className="vendor-list">
                                      {vendors[market._id]?.length ? (
                                        <ul className="list-unstyled">
                                          {vendors[market._id].map((vendor) => (
                                            <li
                                              key={vendor.id}
                                              className="d-flex justify-content-between align-items-center mb-3 py-2 px-3 bg-white rounded-lg shadow-sm"
                                            >
                                              <div className="vendor-details">
                                                <span className="text-dark">
                                                  {vendor.businessName}
                                                </span>
                                                {vendor.contactPerson && (
                                                  <div className="text-muted">
                                                    <small>
                                                      Contact:{" "}
                                                      {vendor.contactPerson}
                                                    </small>
                                                  </div>
                                                )}
                                              </div>
                                              <button
                                                className="btn btn-sm btn-info"
                                                onClick={() =>
                                                  openVendorDetailsModal(vendor)
                                                }
                                              >
                                                <i className="fas fa-eye"></i>{" "}
                                                View Vendor Details
                                              </button>
                                            </li>
                                          ))}
                                        </ul>
                                      ) : (
                                        <div className="text-muted">
                                          Loading vendors...
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p className="text-muted">No markets selected.</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {selectedVendor && (
        <>
          <div
            className={`background-overlay ${selectedVendor ? "blur" : ""}`}
          ></div>

          <div
            className="modal fade show"
            style={{ display: "block" }}
            aria-labelledby="vendorModalLabel"
            aria-hidden="true"
            onClick={() => setSelectedVendor(null)}
          >
            <div className="modal-dialog custom-modal-dialog shadow-lg">
              <div className="modal-content rounded-lg">
                <div className="modal-header">
                  <h5 className="modal-title" id="vendorModalLabel">
                    Vendor Details
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => setSelectedVendor(null)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  {/* Business Information in a row */}
                  <div className="row mb-3">
                    {/* Business Name */}
                    <div className="col-12 col-md-6">
                      <h6 className="font-weight-bold">Business Name:</h6>
                      <p>{selectedVendor.businessName}</p>
                    </div>

                    {/* Contact Person */}
                    <div className="col-12 col-md-6">
                      <h6 className="font-weight-bold">Contact Person:</h6>
                      <p>{selectedVendor.contactPerson}</p>
                    </div>
                  </div>

                  {/* Contact Information in a row */}
                  <div className="row mb-3">
                    {/* Contact Number */}
                    <div className="col-12 col-md-6">
                      <h6 className="font-weight-bold">Contact Number:</h6>
                      <p>{selectedVendor.contactNumber}</p>
                    </div>

                    {/* Category */}
                    <div className="col-12 col-md-6">
                      <h6 className="font-weight-bold">Category:</h6>
                      <p>{selectedVendor.category[0]?.name}</p>
                    </div>
                  </div>

                 
                  <div className="row mb-3">
                    <div className="mb-3 col-6">
                      <h6 className="font-weight-bold">
                        Business Description:
                      </h6>
                      <p>{selectedVendor.businessDescription}</p>
                    </div>

                    <div className="mb-3 col-6">
                      <h6 className="font-weight-bold">Product Messege:</h6>
                      <p>{selectedVendor.addProductMessage}</p>
                    </div>
                  </div>

                  {/* Product and License Images Row */}
                  <div className="image-row">
                    {/* Product Images */}
                    {selectedVendor.productImage1 && (
                      <div className="image-card">
                        <h6 className="font-weight-bold">Product Image 1:</h6>
                        <img
                          src={`${staticImagePath}/${selectedVendor.productImage1}`}
                          alt="Product Image 1"
                        />
                      </div>
                    )}

                    {selectedVendor.productImage2 && (
                      <div className="image-card">
                        <h6 className="font-weight-bold">Product Image 2:</h6>
                        <img
                          src={`${staticImagePath}/${selectedVendor.productImage2}`}
                          alt="Product Image 2"
                        />
                      </div>
                    )}

                    {selectedVendor.productImage3 && (
                      <div className="image-card">
                        <h6 className="font-weight-bold">Product Image 3:</h6>
                        <img
                          src={`${staticImagePath}/${selectedVendor.productImage3}`}
                          alt="Product Image 3"
                        />
                      </div>
                    )}

                    {/* License Images */}
                    {selectedVendor.publicLiabilityInsurance && (
                      <div className="image-card">
                        <h6 className="font-weight-bold">
                          Public Liability Insurance:
                        </h6>
                        <img
                          src={`${staticImagePath}/${selectedVendor.publicLiabilityInsurance}`}
                          alt="Public Liability Insurance"
                        />
                      </div>
                    )}

                    {selectedVendor.foodRegistrationImage && (
                      <div className="image-card">
                        <h6 className="font-weight-bold">
                          Food Registration Image:
                        </h6>
                        <img
                          src={`${staticImagePath}/${selectedVendor.foodRegistrationImage}`}
                          alt="Food Registration Image"
                        />
                      </div>
                    )}

                    {selectedVendor.liquorLicenseImage && (
                      <div className="image-card">
                        <h6 className="font-weight-bold">
                          Liquor License Image:
                        </h6>
                        <img
                          src={`${staticImagePath}/${selectedVendor.liquorLicenseImage}`}
                          alt="Liquor License Image"
                        />
                      </div>
                    )}

                    {selectedVendor.otherLicenseImage && (
                      <div className="image-card">
                        <h6 className="font-weight-bold">
                          Other License Image:
                        </h6>
                        <img
                          src={`${staticImagePath}/${selectedVendor.otherLicenseImage}`}
                          alt="Other License Image"
                        />
                      </div>
                    )}
                  </div>

                  {/* Other Information */}
                  <div className="row mb-3">
                    {selectedVendor.insuranceExpiryDate && (
                      <div className="col-12 col-md-6">
                        <h6 className="font-weight-bold">
                          Insurance Expiry Date:
                        </h6>
                        <p>
                          {new Date(
                            selectedVendor.insuranceExpiryDate
                          ).toLocaleDateString()}
                        </p>
                      </div>
                    )}

                    {selectedVendor.foodRegistrationExpiryDate && (
                      <div className="col-12 col-md-6">
                        <h6 className="font-weight-bold">
                          Food Registration Expiry Date:
                        </h6>
                        <p>
                          {new Date(
                            selectedVendor.foodRegistrationExpiryDate
                          ).toLocaleDateString()}
                        </p>
                      </div>
                    )}
                  </div>

                  <div className="row mb-3">
                    {selectedVendor.liquorLicenseExpiryDate && (
                      <div className="col-12 col-md-6">
                        <h6 className="font-weight-bold">
                          Liquor License Expiry Date:
                        </h6>
                        <p>
                          {new Date(
                            selectedVendor.liquorLicenseExpiryDate
                          ).toLocaleDateString()}
                        </p>
                      </div>
                    )}

                    {selectedVendor.otherLicenseExpiryDate && (
                      <div className="col-12 col-md-6">
                        <h6 className="font-weight-bold">
                          Other License Expiry Date:
                        </h6>
                        <p>
                          {new Date(
                            selectedVendor.otherLicenseExpiryDate
                          ).toLocaleDateString()}
                        </p>
                      </div>
                    )}
                  </div>
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => setSelectedVendor(null)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {/* Modal for sending email */}
      <div
        className={`modal fade ${showModal ? "show" : ""}`}
        style={{ display: showModal ? "block" : "none" }}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        onClick={closeModalOnOutsideClick}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Send Email to Selected Users
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={closeModal}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <input
                type="text"
                className="form-control mb-3"
                placeholder="Email Title"
                value={emailTitle}
                onChange={(e) => setEmailTitle(e.target.value)}
              />
              <textarea
                className="form-control"
                placeholder="Email Content"
                rows="5"
                value={emailContent}
                onChange={(e) => setEmailContent(e.target.value)}
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={closeModal}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={sendEmail}
              >
                Send Email
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllUser;
