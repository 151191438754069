import React, { useEffect, useState } from "react";
import useApiAxios from "../../api/useApiAxios";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./ViewUser.css"; // Import the CSS file

const ViewUser = () => {
  const {
    getAllSubAdmin,
    updateSubAdminApi,
    deleteSubAdminApi,
    searchAdminApi
  } = useApiAxios();
  const [adminData, setAdminData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [userToDelete, setUserToDelete] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0); // Added totalPages state

  // const getUsers = (page, itemsPerPage) => {
  //   console.log("Fetching data for page:", page, "itemsPerPage:", itemsPerPage); // Debug log
  //   getAllSubAdmin(page, itemsPerPage)
  //     .then((response) => {
  //       if (!response.error) {
  //         setAdminData(response.data.data); // Assuming the API response contains 'data'
  //         setTotalItems(response.data.total); // Total number of items
  //         setTotalPages(response.data.totalPages); // Set the total pages from the API response
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data:", error);
  //     });
  // };

  useEffect(() => {
    getUsers(currentPage, itemsPerPage);
  }, [currentPage, itemsPerPage]);

  const getUsers = (page, itemsPerPage) => {
    console.log("Fetching data for page:", page, "itemsPerPage:", itemsPerPage);
    getAllSubAdmin(page, itemsPerPage)
      .then((response) => {
        if (!response.error) {
          setAdminData(response.data.data); // Update the data with the API response
          setTotalItems(response.data.total); // Update total items
          setTotalPages(response.data.totalPages); // Update total pages
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handlePageClick = (data) => {
    const newPage = data.selected;
    setCurrentPage(newPage); // Update current page state
  };

  // Use effect to refetch data on page or itemsPerPage change
  useEffect(() => {
    getUsers(currentPage, itemsPerPage); // Fetch users on page change
  }, [currentPage, itemsPerPage]);

  // Handle items per page change
  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(0);
  };

  // Handle search input
  const handleSearch = async (event) => {
    try {
      const query = event.target.value;
      setSearchQuery(query);
      const response = await searchAdminApi(query);
      setAdminData(response.data.data);
      setCurrentPage(0);
    } catch (error) {
      console.error("Error searching:", error);
    }
  };

  // Handle opening the edit modal
  const openEditModal = (user) => {
    setSelectedUser(user);
    setIsEditModalOpen(true);
  };

  // Handle closing the edit modal
  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedUser(null);
    getUsers(currentPage, itemsPerPage); // Refetch users after editing
  };

  // Handle opening the delete confirmation modal
  const openDeleteModal = (user) => {
    setUserToDelete(user);
    setIsDeleteModalOpen(true);
  };

  // Handle closing the delete modal
  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setUserToDelete(null);
  };

  // Handle delete action
  const handleDelete = async () => {
    if (userToDelete) {
      try {
        await deleteSubAdminApi(userToDelete._id);
        getUsers(currentPage, itemsPerPage); // Refetch users after deletion
      } catch (error) {
        console.error("Error deleting user:", error);
      } finally {
        closeDeleteModal(); // Close modal after action
      }
    }
  };

  // Pagination calculations
  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = adminData;

  // Validation schema for formik
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Name must be at least 2 characters long")
      .required("Name is required")
      .test(
        "no-leading-whitespace",
        "Name cannot start with a space",
        (value) => value && value[0] !== " "
      ),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required")
      .test(
        "no-leading-whitespace",
        "Email cannot start with a space",
        (value) => value && value[0] !== " "
      )
  });

  return (
    <>
      <div
        className={`wrapper ${isEditModalOpen ? "modal-open" : ""}`}
        style={
          isEditModalOpen ? { filter: "blur(5px)", pointerEvents: "none" } : {}
        }
      >
        <div className="main-panel">
          <div className="content">
            <div className="panel-header">
              <div className="page-inner py-5">
                <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
                  <div>
                    <h2 className="pb-2 fw-bold">View Sub Admin</h2>
                    <ul className="breadcrumbs">
                      <li className="nav-home">
                        <Link to="/">
                          <i className="flaticon-home" />
                        </Link>
                      </li>
                      <li className="separator">
                        <i className="flaticon-right-arrow" />
                      </li>
                      <li className="nav-item">
                        <Link to="/">SubAdmin</Link>
                      </li>
                      <li className="separator">
                        <i className="flaticon-right-arrow" />
                      </li>
                      <li className="nav-item">
                        <a href="#">View Sub Admin</a>
                      </li>
                    </ul>
                  </div>
                  <div className="ml-md-auto py-2 py-md-0">
                    <form className="navbar-left navbar-form nav-search mr-md-3">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <button
                            type="submit"
                            className="btn btn-search pr-1"
                            onClick={handleSearch}
                          >
                            <i className="fa fa-search search-icon"></i>
                          </button>
                        </div>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Search ..."
                          value={searchQuery}
                          onChange={handleSearch}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="page-inner mt--5">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">All SubAdmin</h4>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive table-container">
                        <table
                          id="basic-datatables"
                          className="display table table-striped table-hover"
                        >
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Email</th>
                              <th>Phone</th>
                              <th>Organization</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentItems.map((user) => (
                              <tr key={user._id}>
                                <td>{user.name}</td>
                                <td>{user.email}</td>
                                <td>{user.phone}</td>
                                <td>{user.organization}</td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-link btn-primary btn-lg"
                                    onClick={() => openEditModal(user)}
                                  >
                                    <i className="fa fa-edit" />
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-link btn-danger"
                                    onClick={() => openDeleteModal(user)}
                                  >
                                    <i className="fa fa-times" />
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>

                        <div className="d-flex justify-content-between align-items-center pagination-container">
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            pageCount={totalPages} // Use the correct number of pages                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick} // Call handlePageClick on page change
                            subContainerClassName={"pages pagination"}
                            containerClassName={"pagination m-0"}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                          />

                          <div className="form-group d-flex align-items-center items-per-page">
                            <span>User per page:</span>
                            <select
                              id="itemsPerPage"
                              className="form-control m-2"
                              style={{ width: "auto", display: "inline-block" }}
                              value={itemsPerPage}
                              onChange={handleItemsPerPageChange}
                            >
                              <option value={10}> Show 10</option>
                              <option value={15}> Show 15</option>
                              <option value={20}> Show 20</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Edit Modal */}
      {isEditModalOpen && (
        <div
          className="modal fade show"
          style={{ display: "block" }}
          id="editUserModal"
          tabIndex="-1"
          aria-labelledby="editUserModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div
              className="modal-content"
              style={{
                border: "1px solid #ccc",
                boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.5)"
              }}
            >
              <div className="modal-header">
                <h2 className="modal-title" id="editUserModalLabel">
                  Edit User
                </h2>
                <button
                  type="button"
                  className="close"
                  onClick={closeEditModal}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <Formik
                  initialValues={{
                    name: selectedUser ? selectedUser.name : "",
                    email: selectedUser ? selectedUser.email : ""
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    updateSubAdminApi(selectedUser._id, values)
                      .then((response) => {
                        closeEditModal();
                      })
                      .catch((error) => {
                        console.error("Edit failed:", error);
                      })
                      .finally(() => setSubmitting(false));
                  }}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <div className="form-group">
                        <label htmlFor="editedName">Name</label>
                        <Field
                          type="text"
                          name="name"
                          className="form-control"
                          id="editedName"
                          placeholder="Enter name"
                        />
                        <ErrorMessage name="name" component="div" />
                      </div>
                      <div className="form-group">
                        <label htmlFor="editedEmail">Email</label>
                        <Field
                          type="email"
                          name="email"
                          className="form-control"
                          id="editedEmail"
                          placeholder="Enter email"
                        />
                        <ErrorMessage name="email" component="div" />
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Saving..." : "Save"}
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Delete Modal */}
      {isDeleteModalOpen && (
        <div
          id="deleteUserModal"
          aria-labelledby="deleteUserModalLabel"
          aria-hidden="true"
          className="modal fade show" tabIndex="-1" role="dialog" style={{ display: "block" }}
        >
          <div className="modal-dialog">
            <div
              className="modal-content"
              style={{
                border: "1px solid #ccc",
                boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.5)"
              }}
            >
              <div className="modal-header">
                <h5 className="modal-title" id="deleteUserModalLabel">
                  Confirm Deletion
                </h5>
                <button
                  type="button"
                  className="close"
                  onClick={closeDeleteModal}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>Are you sure you want to delete this user?</p>
              </div>
              <div className="modal-footer">
              <button
                  type="button"
                  className="btn btn-danger"
                  onClick={handleDelete}
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={closeDeleteModal}
                >
                  Cancel
                </button>
               
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ViewUser;
