import { useContext, useState } from "react";
import { useNavigate, Link } from 'react-router-dom';
import useApiAxios from "../api/useApiAxios";
import { useFormik } from "formik";
import * as Yup from "yup";

const Login = () => {
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values, { resetForm }) => {
      console.log(values)
      await loginApi(values)
    },
  });
  const { loginApi } = useApiAxios();


  const [showPassword, setShowPassword] = useState(false);
  const onSubmit = async (data) => {
    await loginApi(data);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };




  return (
    <main className="d-flex align-items-center min-vh-100 py-3 py-md-0 login-bg">
      <div className="container">
        <div className="card login-card">
          <div className="row no-gutters">
            <div className="col-md-5">
              <img src="assets/images/login.png" alt="login" className="login-card-img"  style={{
          
          backgroundSize: 'contain', 

        }} />
            </div>
            <div className="col-md-7">
              <div className="card-body">
                <div className="brand-wrapper">
                  <img src="assets/images/logo.svg" alt="logo" className="logo" />
                </div>
                <p className="login-card-description">Sign into your account</p>
                <form

                  onSubmit={formik.handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="email" className="sr-only">Email</label>
                    <input type="email" name="email" id="email" className="form-control" placeholder="Email address" onChange={formik.handleChange("email")}
                      required />
                  </div>
                  <div className="form-group mb-4">
                    <label htmlFor="password" className="sr-only">Password</label>
                    <input onChange={formik.handleChange("password")} placeholder="Password" type={showPassword ? "text" : "password"} id="password" className="form-control" name="password" required />
                    <i className={`toggle-password ${showPassword ? "fa fa-eye" : "fa fa-eye-slash"}`} onClick={togglePasswordVisibility}></i>
                  </div>
                  <div className="col-6 mb-4">
                    <div className="form-check">
                      <input
                        className="form-check-input mr-1"
                        type="checkbox"
                        defaultValue=""
                        id="remember"
                      />
                      <label
                        className="form-check-label font14"
                        htmlFor="remember"
                      >
                        {" "}
                        Remember me{" "}
                      </label>
                    </div>
                  </div>
                  <button className="btn btn-primary w-100 mb-4" type="submit" >Login</button>
                </form>
                <Link to="/forgetPassword" className="forgot-password-link">Forgot password?</Link>
                {/* <nav className="login-card-footer-nav">
                  <Link to="#!">Terms of use.</Link>
                  <Link to="#!">Privacy policy</Link>
                </nav> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Login;
