import React, { useState } from "react";
import { Link } from "react-router-dom";
import useApiAxios from "../../api/useApiAxios";
import { useContext } from "react";
import { Context } from "../../context/Context";

const Header = ({props }) => {
  const { userData } = useContext(Context);
  const { logOutApi } = useApiAxios();  
  return (
    <div class="main-header">
      <div class="logo-header" data-background-color="dark">
        <Link to="/" class="logo">
          <img
            src="/assets/img/logo.svg"
            alt="navbar brand"
            class="navbar-brand"
          />
        </Link>
        <button
          class="navbar-toggler sidenav-toggler ml-auto"
          type="button"
          data-toggle="collapse"
          data-target="collapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon">
            <i class="icon-menu"></i>
          </span>
        </button>
        <button class="topbar-toggler more">
          <i class="icon-options-vertical"></i>
        </button>
        {/* <div class="nav-toggle">
          <button
            class="btn btn-toggle toggle-sidebar"
            onClick={() =>
              props.setSideBarEnable(props.sideBarEnable ? false : true)
            }
          >
            <i class="icon-menu"></i>
          </button>
        </div> */}
      </div>
      <nav
        class="navbar navbar-header navbar-expand-lg"
        data-background-color="white"
      >
        <div class="container-fluid">
        
          <ul class="navbar-nav topbar-nav ml-md-auto align-items-center">
            <li class="nav-item toggle-nav-search hidden-caret">
              <a
                class="nav-link"
                data-toggle="collapse"
                href="#search-nav"
                role="button"
                aria-expanded="false"
                aria-controls="search-nav"
              >
                <i class="fa fa-search"></i>
              </a>
            </li>
            {/* <li class="nav-item dropdown hidden-caret">
              <a
                class="nav-link dropdown-toggle"
                href="/"
                id="messageDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="fa fa-envelope"></i>
              </a>
              <ul
                class="dropdown-menu messages-notif-box animated fadeIn"
                aria-labelledby="messageDropdown"
              >
                <li>
                  <div class="dropdown-title d-flex justify-content-between align-items-center">
                    Messages
                    <a href="/" class="small">
                      Mark all as read
                    </a>
                  </div>
                </li>
                <li>
                  <div class="message-notif-scroll scrollbar-outer">
                    <div class="notif-center">
                      <a href="/">
                        <div class="notif-img">
                          <img
                            src="/assets/img/jm_denis.jpg"
                            alt="Img Profile"
                          />
                        </div>
                        <div class="notif-content">
                          <span class="subject">Jimmy Denis</span>
                          <span class="block">How are you ?</span>
                          <span class="time">5 minutes ago</span>
                        </div>
                      </a>
                      <a href="/">
                        <div class="notif-img">
                          <img
                            src="/assets/img/chadengle.jpg"
                            alt="Img Profile"
                          />
                        </div>
                        <div class="notif-content">
                          <span class="subject">Chad</span>
                          <span class="block">Ok, Thanks !</span>
                          <span class="time">12 minutes ago</span>
                        </div>
                      </a>
                      <a href="/">
                        <div class="notif-img">
                          <img src="/assets/img/mlane.jpg" alt="Img Profile" />
                        </div>
                        <div class="notif-content">
                          <span class="subject">Jhon Doe</span>
                          <span class="block">
                            Ready for the meeting today...
                          </span>
                          <span class="time">12 minutes ago</span>
                        </div>
                      </a>
                      <a href="/">
                        <div class="notif-img">
                          <img src="/assets/img/talha.jpg" alt="Img Profile" />
                        </div>
                        <div class="notif-content">
                          <span class="subject">Talha</span>
                          <span class="block">Hi, Apa Kabar ?</span>
                          <span class="time">17 minutes ago</span>
                        </div>
                      </a>
                    </div>
                  </div>
                </li>
                <li>
                  <a class="see-all" href="/">
                    See all messages<i class="fa fa-angle-right"></i>{" "}
                  </a>
                </li>
              </ul>
            </li>
            <li class="nav-item dropdown hidden-caret">
              <a
                class="nav-link dropdown-toggle"
                href="/"
                id="notifDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="fa fa-bell"></i>
                <span class="notification">4</span>
              </a>
              <ul
                class="dropdown-menu notif-box animated fadeIn"
                aria-labelledby="notifDropdown"
              >
                <li>
                  <div class="dropdown-title">You have 4 new notification</div>
                </li>
                <li>
                  <div class="notif-scroll scrollbar-outer">
                    <div class="notif-center">
                      <a href="/">
                        <div class="notif-icon notif-primary">
                          {" "}
                          <i class="fa fa-user-plus"></i>{" "}
                        </div>
                        <div class="notif-content">
                          <span class="block">New user registered</span>
                          <span class="time">5 minutes ago</span>
                        </div>
                      </a>
                      <a href="/">
                        <div class="notif-icon notif-success">
                          {" "}
                          <i class="fa fa-comment"></i>{" "}
                        </div>
                        <div class="notif-content">
                          <span class="block">Rahmad commented on Admin</span>
                          <span class="time">12 minutes ago</span>
                        </div>
                      </a>
                      <a href="/">
                        <div class="notif-img">
                          <img
                            src="/assets/img/profile2.jpg"
                            alt="Img Profile"
                          />
                        </div>
                        <div class="notif-content">
                          <span class="block">Reza send messages to you</span>
                          <span class="time">12 minutes ago</span>
                        </div>
                      </a>
                      <a href="/">
                        <div class="notif-icon notif-danger">
                          {" "}
                          <i class="fa fa-heart"></i>{" "}
                        </div>
                        <div class="notif-content">
                          <span class="block">Farrah liked Admin</span>
                          <span class="time">17 minutes ago</span>
                        </div>
                      </a>
                    </div>
                  </div>
                </li>
                <li>
                  <a class="see-all" href="/">
                    See all notifications<i class="fa fa-angle-right"></i>{" "}
                  </a>
                </li>
              </ul>
            </li>
            <li class="nav-item dropdown hidden-caret">
              <a
                class="nav-link"
                data-toggle="dropdown"
                href="/"
                aria-expanded="false"
              >
                <i class="fas fa-layer-group"></i>
              </a>
              <div class="dropdown-menu quick-actions quick-actions-info animated fadeIn">
                <div class="quick-actions-header">
                  <span class="title mb-1">Quick Actions</span>
                  <span class="subtitle op-8">Shortcuts</span>
                </div>
                <div class="quick-actions-scroll scrollbar-outer">
                  <div class="quick-actions-items">
                    <div class="row m-0">
                      <a class="col-6 col-md-4 p-0" href="/">
                        <div class="quick-actions-item">
                          <i class="flaticon-file-1"></i>
                          <span class="text">Generated Report</span>
                        </div>
                      </a>
                      <a class="col-6 col-md-4 p-0" href="/">
                        <div class="quick-actions-item">
                          <i class="flaticon-database"></i>
                          <span class="text">Create New Database</span>
                        </div>
                      </a>
                      <a class="col-6 col-md-4 p-0" href="/">
                        <div class="quick-actions-item">
                          <i class="flaticon-pen"></i>
                          <span class="text">Create New Post</span>
                        </div>
                      </a>
                      <a class="col-6 col-md-4 p-0" href="/">
                        <div class="quick-actions-item">
                          <i class="flaticon-interface-1"></i>
                          <span class="text">Create New Task</span>
                        </div>
                      </a>
                      <a class="col-6 col-md-4 p-0" href="/">
                        <div class="quick-actions-item">
                          <i class="flaticon-list"></i>
                          <span class="text">Completed Tasks</span>
                        </div>
                      </a>
                      <a class="col-6 col-md-4 p-0" href="/">
                        <div class="quick-actions-item">
                          <i class="flaticon-file"></i>
                          <span class="text">Create New Invoice</span>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </li> */}
            <li class="nav-item dropdown hidden-caret">
              <a
                class="dropdown-toggle profile-pic"
                data-toggle="dropdown"
                href="/"
                aria-expanded="false"
              >
                <div class="avatar-sm">
                  <img
                    src="/assets/img/profile.jpg"
                    alt="..."
                    class="avatar-img rounded-circle"
                  />
                </div>
              </a>
              <ul class="dropdown-menu dropdown-user animated fadeIn">
                <div class="dropdown-user-scroll scrollbar-outer">
                  <li>
                    <div class="user-box">
                      <div class="avatar-lg">
                        <img
                          src="/assets/img/profile.jpg"
                          alt="image profile"
                          class="avatar-img rounded"
                        />
                      </div>
                      <div class="u-text">
                        <h4>Hizrian</h4>
                        <p class="text-muted">hello@example.com</p>
                        <Link
                          to="/profile"
                          class="btn btn-xs btn-secondary btn-sm"
                        >
                          View Profile
                        </Link>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="dropdown-divider"></div>
                    <Link to ="/profile" class="dropdown-item">
                      My Profile
                    </Link>
                    <div class="dropdown-divider"></div>
                    <Link to class="dropdown-item" onClick={logOutApi}>
                      Logout
                    </Link>
                  </li>
                </div>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Header;
